import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Header = ({ scrollRef, data }) => {
  const onClickScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="intro-text">
            <h1>{data.title}</h1>
            <h2>{data.description}</h2>
          </div>
        </div>
        <div className="scroll-down-container">
          <FontAwesomeIcon
            onClick={onClickScroll}
            id="scroll-indicator"
            className="fa-3x fa-bounce"
            icon="fa-chevron-down"
          />
        </div>
      </div>
    </header>
  );
};
