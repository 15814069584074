import React, { useState, useRef } from "react";
import { Header } from "./header";
import { About } from "./about";
import { Works } from "./works";
import { Products } from "./products";
import { Social } from "./social";
import { Contact } from "./contact";
import JsonData from "../../../data/data-in.json";

export const Home = () => {
  const [homeData] = useState(JsonData);
  const scrollRef = useRef(null);
  return (
    <div>
      <Header data={homeData.Header} scrollRef={scrollRef}></Header>
      <About data={homeData.About} ref={scrollRef}></About>
      <Works data={homeData.Works}></Works>
      <Products data={homeData.Products}></Products>
      <Social data={homeData.Social}></Social>
      <Contact data={homeData.Contact}></Contact>
    </div>
  );
};
