import React, { useEffect, forwardRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import AboutImg from "../../../assets/about-placeholder.png";

export const About = forwardRef((props, ref) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div id="about" ref={ref}>
      <Container>
        <Row>
          <Col>
            <div className="content" data-aos="fade-down">
              <h2>{props.data.title}</h2>
              <p>{props.data.text}</p>
            </div>
          </Col>
          <Col md="auto">
            <img className="img" data-aos="fade-up" src={AboutImg} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
});
