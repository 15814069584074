import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";

export const Products = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="products">
      <div className="container">
        <div className="section-title">
          <h2>{props.data.title}</h2>
        </div>
        <Container>
          <Row>
            {props.data.products.map((prod) => (
              <Col className="d-flex justify-content-center" key={prod.id}>
                <div className="product-box">
                  <div className="image-container">
                    <a href="#">
                      <div className="image-overlay"></div>
                      <img
                        className="image"
                        src={require(process.env.REACT_APP_ASSET_PATH +
                          `${prod.image}`)}
                        alt=""
                      />
                      <div className="image-details">
                        <h3>{prod.name}</h3>
                        <p>This is a short description</p>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};
