import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

export const Social = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div id="social">
      <Container>
        <Row>
          <Col className="content">
            <h2 data-aos="fade-up">{props.data.title}</h2>
            <p data-aos="fade-up">{props.data.text}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <img
              className="img"
              data-aos="fade-up"
              src={require(process.env.REACT_APP_ASSET_PATH +
                `${props.data.image}`)}
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
