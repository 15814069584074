import React from "react";

export const Navbar = () => {
  return (
    <div id="primary-nav">
      <div className="menu-container">
        <div className="menu">
          <a href="/" className="menu-link">
            Home
          </a>
          {/* <a href="/gallery" className="menu-link">
            Gallery
          </a> */}
        </div>
      </div>
    </div>
  );
};
