import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const Works = (props) => {
  return (
    <div id="works">
      <Container>
        <Row>
          <Col>
            <div className="content-title">
              <h2>Yang kami lakukan </h2>
            </div>
            <div className="content-first">
              <div className="works-item">
                <img
                  className="img"
                  src={require(process.env.REACT_APP_ASSET_PATH +
                    `${props.data.item1.image}`)}
                  alt=""
                />
                <h3>{props.data.item1.title}</h3>
                <p>{props.data.item1.description}</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="content-second">
              <div className="works-item">
                <img
                  className="img"
                  src={require(process.env.REACT_APP_ASSET_PATH +
                    `${props.data.item2.image}`)}
                  alt=""
                />
                <h3>{props.data.item2.title}</h3>
                <p>{props.data.item2.description}</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className="content-third">
              <div className="works-item">
                <img
                  className="img"
                  src={require(process.env.REACT_APP_ASSET_PATH +
                    `${props.data.item3.image}`)}
                  alt=""
                />
                <h3>{props.data.item3.title}</h3>
                <p>{props.data.item3.description}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
