import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArroLogo from "../../../assets/arro-logo.png";
import { Container, Row, Col } from "react-bootstrap";

export const Contact = (props) => {
  return (
    <div id="contact">
      <Container fluid="lg">
        <Row>
          <Col>
            <div className="footer-logo">
              <img src={ArroLogo} alt="" />
            </div>
          </Col>
          <Col sm="auto">
            <Row>
              <Col sm="auto">
                <div className="footer-info">
                  <h3>SOCIAL</h3>
                  <div className="info">
                    <FontAwesomeIcon
                      className="info-fa-icon"
                      icon="fa-solid fa-brands fa-instagram"
                    />
                    <p>{props.data.instagram}</p>
                  </div>
                </div>
              </Col>
              <Col sm="auto">
                <div className="footer-info">
                  <h3>CONTACT</h3>
                  <div className="info">
                    <FontAwesomeIcon
                      className="info-fa-icon"
                      icon="fa-regular fa-envelope"
                    />
                    <p>{props.data.email}</p>
                  </div>
                  <div className="info">
                    <FontAwesomeIcon
                      className="info-fa-icon"
                      icon="fa-solid fa-location-dot"
                    />
                    <p>{props.data.address}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="contact-footer">
        <div className="container">
          <span>&copy; 2023 arrocoffee</span>
        </div>
      </div>
    </div>
  );
};
