import React from "react";

export const Gallery = (props) => {
  return (
    <div id="Gallery">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div>
      </div>
    </div>
  );
};
